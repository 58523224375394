<template>
  <section class="org-list">
    <a-table
      ref="taskListRef"
      :columns="columns"
      :data-source="taskList"
      rowKey="id"
      :scroll="{
        y: tableHeight,
        x: 1200,
        scrollToFirstRowOnChange: true,
      }"
      :pagination="pagination"
    >
    </a-table>
  </section>
</template>
<script>
import {
  defineComponent,
  nextTick,
  onMounted,
  onUnmounted,
  reactive,
  ref,
} from "vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import { useTableHeight } from "@/utils/common";

export default defineComponent({
  name: "CostCtrList",
  components: {},
  setup() {
    const { $api } = useGlobalPropertyHook();

    const taskListRef = ref();
    const columns = [
      {
        title: "User name",
        dataIndex: "userName",
        key: "userName",
        ellipsis: true,
        width: 150,
      },
      {
        title: "GGID",
        dataIndex: "ggid",
        key: "ggid",
        ellipsis: true,
        width: 150,
      },
      {
        title: "Task Name",
        dataIndex: "taskName",
        key: "taskName",
        ellipsis: true,
        width: 150,
      },
      {
        title: "LM Name",
        dataIndex: "lmName",
        key: "lmName",
        ellipsis: true,
        width: 150,
      },
      {
        title: "Org5.1",
        dataIndex: "orgFiveOne",
        key: "orgFiveOne",
        ellipsis: true,
        width: 150,
      },
      {
        title: "Team",
        dataIndex: "orgThreeTwo",
        key: "orgThreeTwo",
        ellipsis: true,
        width: 150,
      },
      {
        title: "Offering",
        dataIndex: "orgFourOneOne",
        key: "orgFourOneOne",
        ellipsis: true,
        width: 150,
      },
      {
        title: "Begin Time",
        dataIndex: "beginTime",
        key: "beginTime",
        ellipsis: true,
        width: 150,
      },
      {
        title: "End Time",
        dataIndex: "endTime",
        key: "endTime",
        ellipsis: true,
        width: 150,
      },
      {
        title: "Real Begin Time",
        dataIndex: "realBeginTime",
        key: "realBeginTime",
        ellipsis: true,
        width: 150,
      },
      {
        title: "Real End Time",
        dataIndex: "realEndTime",
        key: "realEndTime",
        ellipsis: true,
        width: 150,
      },
    ];
    const pagination = reactive({
      current: 1,
      pageSize: 12,
      pageSizeOptions: ['12','20','30','40'],
      total: 0,
      showSizeChanger: true,
      showTotal: (total) => {
        return `共 ${total} 条`;
      },
      onChange: (page) => {
        pagination.current = page;
        doSearch(searchCondition);
      },
      onShowSizeChange: (_, size) => {
        pagination.pageSize = size;
        pagination.current = 1;
        doSearch(searchCondition);
      },
    });
    const taskList = ref();
    let searchCondition = {};

    const doSearch = (searchParams = searchCondition) => {
      const params = {
        pageIndex: pagination.current,
        pageSize: pagination.pageSize,
        ...searchParams,
      };
      $api.taskExportList(params).then((res) => {
        pagination.total = res.data.total;
        taskList.value = res.data.resource;
      });
    };

    const searchClick = (searchParams) => {
      pagination.current = 1;
      searchCondition = searchParams;
      doSearch(searchCondition);
    };

    const tableHeight = ref(0);

    const getSize = () => {
      nextTick(() => {
        tableHeight.value = useTableHeight();
      });
    };

    window.addEventListener("resize", getSize);

    onMounted(() => {
      getSize();
    });

    onUnmounted(() => {
      window.removeEventListener("resize", getSize);
    });

    return {
      taskListRef,
      columns,
      pagination,
      taskList,
      searchClick,
      tableHeight,
      doSearch,
    };
  },
});
</script>
<style scoped lang="less">
// 覆盖ant-design样式
::v-deep .ant-table-body {
  overflow: auto;
}

:deep(.ant-table-thead) {
  > tr > th {
    white-space: nowrap;
  }
}

:deep(.ant-table-tbody) {
  > tr > td {
    white-space: nowrap;
  }
}

.org-list {
  display: flex;
  width: 100%;
  max-height: calc(100% - 88px);

  .ant-table-wrapper {
    flex: 1;
    width: 0;
  }

  .org-info {
    width: 300px;
    //margin-left: 20px;
    background: #f4f4f4;
  }
}
</style>
