<template>
  <section class="app-container">
    <Filter
      @search="handleSearch"
      @export-click="exportClick"
      @refresh-table="handleResetTable"
    />
    <TaskList ref="taskListRef"></TaskList>
  </section>
</template>
<script>
import { defineComponent, ref } from "vue";
import Filter from "./components/Filter.vue";
import TaskList from "./components/TaskList";
import { downloadFile } from "@/utils/common";
import { useGlobalPropertyHook } from "@/hooks/common";

export default defineComponent({
  name: "exportTask",
  components: {
    TaskList,
    Filter,
  },
  setup() {
    const { $api } = useGlobalPropertyHook();

    const taskListRef = ref();
    const handleSearch = (searchParams) => {
      taskListRef.value.searchClick(searchParams);
    };
    const handleResetTable = () => {
      taskListRef.value.doSearch();
    };

    const exportClick = (searchParams) => {
      $api.taskExport(searchParams).then((res) => {
        downloadFile(res.file, res.filename);
      });
    };
    return {
      taskListRef,
      handleSearch,
      handleResetTable,
      exportClick,
    };
  },
});
</script>